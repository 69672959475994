import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['userGroups']),

    isAdmin () { return this.hasGroup('ADMIN') },
    isPM () { return this.hasGroup('PM_GUY') },

    canAccess () {
      // Kitchen doesn\t work for those apps so we need to restrict access
      const blitzUnifiedAppId = 'fd5767e5-c2e2-4874-85b9-6023b3859dbe' // Blitz
      const wizzUnifiedAppId = 'a0ad393b-cc1a-4528-aae5-d994dcdf0f2b' // Wizz
      const testingUnifiedAppId = 'dfb9e765-457f-4995-af0e-b3702bc2a1c4' // Testing on dev
      const unifiedAppIdsRestricted = [blitzUnifiedAppId, wizzUnifiedAppId, testingUnifiedAppId]
      if (unifiedAppIdsRestricted.includes(this.$route.query.appId)) return false
      if (!this.restrict) return true
      else {
        // Only those groups can access the page
        if (this.restrict.groups) {
          return this.restrict.groups.some(this.hasGroup)
        }
      }
    }
  },

  methods: {
    hasGroup (group) {
      return this.userGroups.includes(group)
    }
  }
}
