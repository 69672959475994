<template lang="pug">
  v-dialog(
    v-model='show',
    width='60%'
  )
    template(v-slot:activator='{ on }')
      v-btn(
        v-on='on',
        outlined
      )
        v-icon {{ icons.mdiPlus }}
        .pl-2 Create App

    v-card
      v-card-title Create Apps

      v-divider

      v-card-text
        v-stepper(v-model='stepper')
          v-stepper-header
            v-stepper-step(:complete='stepper > 1' step='1') Platform

            v-divider

            v-stepper-step(:complete='stepper > 2' step='2') Unified App

            v-divider

            v-stepper-step(:complete='stepper > 3' step='3') App Information

          v-stepper-items
            v-stepper-content(step='1')
              v-card
                v-card-text.pb-0
                  v-row(justify='center')
                    v-col.text-center(cols='4')
                      v-btn(
                        outlined,
                        large,
                        color='cyan',
                        @click="selectPlatform('ios')"
                      )
                        v-icon {{ icons.mdiAppleIos }}
                        .pl-2 iOS
                    v-col.text-center(cols='4')
                      v-btn(
                        outlined,
                        large,
                        color='green',
                        @click="selectPlatform('android')"
                      )
                        v-icon {{ icons.mdiAndroid }}
                        .pl-2 Android

                v-card-actions
                  v-spacer
                  v-btn(color='secondary', @click='close') Cancel

            v-stepper-content(step='2')
              v-card
                v-card-text.pb-0
                  v-form(v-model='valid')
                    v-container(pa-0)
                      v-row
                        v-col(cols='12')
                          v-text-field(
                            v-model='form.app.name',
                            :rules='[appNameRule]'
                            label='Name of the App',
                            outlined,
                            required
                          )
                        v-col(cols='12')
                          v-text-field(
                            v-model='form.app.iconUrl',
                            label='Icon URL of the app (Optional)',
                            outlined
                          )
                        v-col(cols='6')
                          v-select(
                            v-model='form.app.business',
                            label='Business',
                            :items='businesses',
                            outlined
                          )
                        v-col(cols='6')
                          v-select(
                            v-model='form.app.genre',
                            label='Genre',
                            :items='genres',
                            outlined
                          )

                v-card-actions
                  v-spacer
                  v-btn(color='secondary', @click='stepper = 1') Back
                  v-btn(color='primary', @click='stepper = 3', :disabled='!valid') Continue

            v-stepper-content(step='3')
              v-card
                v-card-text.pb-0
                  create-app(:platform='form.platform', :remote='true', ref='appInfo')

                v-card-actions
                  v-spacer
                  v-btn(color='secondary', @click='stepper = 2') Back
                  v-btn(color='primary', @click='create', :disabled='!valid') Create

      v-divider

      v-card-actions
        v-spacer
        v-btn(@click='close', text) Cancel
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

import CreateApp from '@/components/general/createApp.vue'

import { mdiPlus, mdiAndroid, mdiAppleIos } from '@mdi/js'

export default {
  name: 'CreateApps',

  components: { CreateApp },

  data: () => ({
    show: false,
    stepper: 1,
    valid: false,

    appNameRule: (value) => (!!value && value.length >= 4) || 'At least 4 letters',

    icons: { mdiPlus, mdiAndroid, mdiAppleIos },
    platforms: ['ios', 'android'],
    businesses: [
      { text: 'Voodoo', value: 'VOODOO' },
      { text: 'Voodoo Labs', value: 'VOODOO_LABS' },
      { text: 'We Mom\'s', value: 'WEMOMS' },
      { text: 'Beach Bum', value: 'BEACH_BUM' }
    ],
    genres: [
      { text: 'Hyper casual', value: 'HYPER_CASUAL' },
      { text: 'Casual', value: 'CASUAL' },
      { text: 'Social', value: 'SOCIAL' },
      { text: 'Gambling', value: 'GAMBLING' }
    ],
    form: {
      platform: 'ios',
      app: {
        name: null,
        iconUrl: null,
        business: 'VOODOO',
        genre: 'HYPER_CASUAL'
      }
    }
  }),

  computed: {
    ...mapGetters('apps', [
      'apps'
    ])

  },

  methods: {
    ...mapMutations('apps', [
      'setCurrentApp',
      'setLastCreatedId'
    ]),
    ...mapActions('apps', [
      'createApp',
      'retrieveApps'
    ]),

    close () {
      this.show = false
    },

    selectPlatform (platform) {
      this.form.platform = platform
      this.stepper++
    },

    async create () {
      const params = {
        ...this.form,
        isPublished: this.$refs.appInfo.isPublished,
        storeId: this.$refs.appInfo.storeId,
        bundleId: this.$refs.appInfo.bundleId,
        publidashId: this.$refs.appInfo.publidashId,
        releaseStatus: this.$refs.appInfo.releaseStatus
      }

      await this.createApp(params)

      const createdId = this.$store.state.apps.createdId

      this.setLastCreatedId(null)

      await this.$router.push({
        path: '/app/general/information',
        query: {
          appId: createdId
        }
      })
    }
  }
}
</script>
