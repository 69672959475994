<template lang="pug">
  v-form(v-model='valid')
    v-container
      v-row(align='center')
        template(v-if='text')
          v-col.text-center(cols='12')
            .subtitle-1 No App on {{ platform }}.
            i.subtitle-2 You can create one by filling up this form.

        template(v-else)
          v-container.text-center
            v-icon(
              large,
              :color="isIos ? 'cyan' : 'green'"
            ) {{ isIos ? icons.mdiAppleIos : icons.mdiAndroid }}

        v-col(cols='12')
          v-switch(
            v-model='isPublished',
            label="Is published?"
          )

        v-col(cols='12')
          v-text-field(
            v-model='storeId',
            :rules='[isIos ? rules.storeId : rules.bundleId]'
            :label="isIos ? 'Store ID' : 'Bundle ID'",
            outlined
          )

        v-col(cols='12', v-if='isIos && !isPublished')
          v-text-field(
            v-model='bundleId',
            :rules='[rules.bundleId]'
            label='Bundle ID (Optional)',
            outlined
          )

        v-col(cols='12')
          v-text-field(
            v-model='publidashId',
            label='Publidash Game ID (Optional)',
            type='number',
            outlined
          )

        v-col(cols='12')
          v-select(
            v-model='releaseStatus',
            :items='statuses'
            label='Release Status',
            outlined
          )

        template(v-if='!remote')
          v-col.text-center(cols='12')
            v-btn(
              :disabled='!valid',
              :loading='loading'
              @click='create'
            ) Create
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { mdiAppleIos, mdiAndroid } from '@mdi/js'

export default {
  name: 'CreateApp',

  props: {
    platform: String,
    text: Boolean,

    remote: Boolean
  },

  mounted () {
    if (!this.isIos && this.app) this.storeId = this.app.apps[0].bundleId
  },

  data () {
    return {
      valid: null,

      rules: {
        storeId: (value) => !!value && !isNaN(parseInt(value)),
        bundleId: (value) => !!value && /^[a-zA-Z0-9]+\.[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?(\.[a-zA-Z0-9]+)?$/.test(value)
      },

      bundleId: null,
      storeId: null,
      publidashId: null,
      isPublished: true,
      releaseStatus: 'released',

      statuses: [
        { text: 'Released', value: 'released' },
        { text: 'Prototype', value: 'prototype' }
      ],

      icons: { mdiAppleIos, mdiAndroid }
    }
  },

  computed: {
    ...mapGetters('apps', [
      'app',
      'apps'
    ]),
    loading () {
      return this.$store.state.apps.creating[this.platform]
    },

    isIos () {
      return this.platform === 'ios'
    },
    params () {
      return {
        app: this.app
          ? {
              id: this.app.id,
              business: this.app.business,
              genre: this.app.genre,
              iconUrl: this.app.iconUrl,
              name: this.app.name
            }
          : undefined,
        isPublished: this.isPublished,
        platform: this.platform,
        storeId: this.storeId,
        bundleId: this.bundleId,
        publidashId: this.publidashId,
        releaseStatus: this.releaseStatus
      }
    }
  },

  methods: {
    ...mapMutations('apps', [
      'setCurrentApp'
    ]),
    ...mapActions('apps', [
      'createApp',
      'retrieveApps'
    ]),

    async create () {
      await this.createApp(this.params)

      this.$emit('created', this.platform)
    }
  }
}
</script>
